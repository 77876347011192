dialog::backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.md-container {
  font-family: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.md-modal {
  display: flex; /* Enable Flexbox */
  flex-direction: column; /* Stack children vertically */
  justify-content: space-between; /* Ensure title and footer stay at the top and bottom */
  border: 0;
  width: 520px;
  transform-origin: 495.5px 142px;
  height: 200px;
  padding: 0;
  box-shadow:
    0 3px 6px -4px #0000001f,
    0 6px 16px #00000014,
    0 9px 28px 8px #0000000d;
  border-radius: 2px;
  background-clip: padding-box;
  border-color: #fff;
  font-family: 'Poppins', Helvetica, Arial, sans-serif !important;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  font-feature-settings: 'tnum';
}

.md-divider {
  padding: 0;
  margin: 0;
}

.md-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 16px 24px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 2px 2px 0 0;
}

.md-title-container .md-title {
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  line-height: 16px;
  color: #000000d9;
}

.md-title-container .md-close {
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: #00000073;
}

.md-title-container .md-close:hover {
  color: #000000a0;
}

.md-content {
  padding: 24px;
  box-sizing: border-box;
  border-radius: 0 0 2px 2px;
  background-color: #fff;
}

.md-footer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 10px 16px;
  box-sizing: border-box;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0 0 2px 2px;
}
